.menu-page {
    .box-part{
        padding: 4px;
        margin: 10px 0px 5px 0px;
        border: 1px #57636C solid;
        box-shadow: 4px 4px 4px 0 rgb(0 0 0 / 20%);
        cursor: pointer;
    }
    .fa, .title {
        color:#57636C;
    }
    .title {
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.25rem;
        padding-bottom: 0.3rem;
    }
}