.name-text {
  font-size: 0.7em;
}
.btn-back {
  padding: 0.5rem 0rem;
}
.btn-edit {
  padding: 0.5rem 0rem 0rem 0rem;
}
.navbar {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.navbar-brand {
  font-size: 1rem;
  font-weight: bold;
  max-width: 75%;
}
.navbar-toggler {
  padding: 0rem;
}
.edit-line {
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
}
.edit-row {
  flex-grow: 0;
  margin-right: auto;
  margin-left: auto;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: stretch;
  padding-top: 0.2rem;
}
.footer-icon {
  margin-right: 0.2em;
}
.navbar-collapse {
  flex-grow: 0;
}