@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/scss/main';
@import '~font-awesome/scss/font-awesome';
@import 'colors';

a, a:visited, a:hover
{
  text-decoration: none;
  color: #000;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
}
body {
  min-height: 100%;
  padding-bottom: 74px; /* Margin bottom by footer height */
  padding-top: 48px; /*padding top by header height*/
  padding-top: calc(48px + env(safe-area-inset-top));
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ios-bar {
  height: env(safe-area-inset-top);
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0;
  padding: 0;
  background-color: $main-background;
  z-index: 1;
}
.fixed-top {
  /*ios style*/
  top: env(safe-area-inset-top);
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.footer {
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
  border-top: 1px solid #CECECE;
}
.provider-logo-footer {
  bottom: 0;
  width: 100%;
  height: 80px;
}
.version {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 0.9rem;
}
@media (max-height: 550px) {
  .footer, .provider-logo-footer, .version {
    position: relative !important;
    bottom: auto !important;
    margin-top: 80px !important;
  }
  .footer {
    margin-top: 40px !important;
  }
  .version {
    margin-top: 0px !important;
  }
}
/*--------- end of sticky footer -----------*/
.provider-logo-text {
  text-decoration: none;
  font-size: 1.1rem;
}
.provider-logo-small {
  width: 90px; 
  margin: 0 auto;
}
.bigger
{
  font-size: 1.25rem;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color:$black;
  top: 0px;
  left: 0px;
  z-index: 1;

  .spinner {
    margin:0 auto;
    margin-top:200px;
    border: 16px solid $spinner-circle;
    border-radius: 50%;
    border-top: 16px solid $spinner-moving;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.hidden {
  display: none;
}
.navbar {
  padding-left:15px;
  padding-right: 15px;
  h3 {
    margin:0;
  }
}
.card-body, .media-body {
  font-size: 0.7rem;
  h5 {
    font-size: 0.8rem;
  }
  padding: 0.25rem;
}
.card {
  border:1px solid transparent;
  text-align: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.25rem;
  }
}
.card-title {
  margin-bottom: 0;
}
/*----styles for desktop-----*/
@include media-breakpoint-up(sm) {
  .card-body h5, .media-body h5, .card-title {
    font-size: 1.5rem;
  }
}
/*-----end of styles for desktop--------*/
/*----styles for md tablet-----*/
@include media-breakpoint-down(md) {
  .edit-row {
    margin-left: auto;
  }
}
/*-----end of styles for md tablet--------*/
.media {
  border-bottom: 1px dotted $dark-gray;
  img {
    max-width: 64px;
    max-height: 64px;
  }
}
.scrollable-table {
  border: 1px solid $dark-gray;
  table-layout: fixed;
  word-wrap: break-word;

  th, td {
    vertical-align: middle;
    white-space: pre-wrap;
    padding: 0.4rem;
  }

  thead {
    display: table;
    overflow: hidden;
    width: calc(100% - 16px);
  }

  tbody {
    display: block;
    height: calc(100vh - 310px);
    overflow-y: scroll;
  }

  th:nth-child(1), td:nth-child(1) {
    width: calc(50vw);
  }

  th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3) {
    width: calc(15vw);
  }

  th:nth-child(4), td:nth-child(4) {
    width: calc(20vw);
  }

  th:nth-child(5), td:nth-child(5) {
    width: calc(0vw);
  }

  input.form-control {
    padding: 0px 1px 0px 1px;
    height: 1.6rem;
  }
}
.container-limited-width {
  width: 60%;
  margin: 0 auto;
}
app-checkout {
  .scrollable-table {
      th:nth-child(1), td:nth-child(1) {
      width: calc(45vw) !important;
    }
    th:nth-child(2), td:nth-child(2) {
      width: calc(15vw) !important;
    }
    th:nth-child(3), td:nth-child(3) {
      width: calc(15vw) !important;
    }
    th:nth-child(4), td:nth-child(4) {
      width: calc(15vw) !important;
    }
    th:nth-child(5), td:nth-child(5) {
      width: calc(10vw) !important;
    }
    tbody {
      height: calc(100vh - 300px);
    }
  }
}
app-products { 
  .scrollable-table tbody {
    height: calc(100vh - 360px);
  }
}

/*----styles for mobile-----*/
@include media-breakpoint-down(sm) {
  .container-limited-width {
    width: auto;
  }
  .scrollable-table {
    font-size: 0.7rem;
    th, td, .form-control {
      padding: 0.2rem;
      font-size: 0.7rem;
    }
    thead {
      width: 100%;
    }
    tbody {
      overflow-y: auto;
    }
  }
}
/*-----end of styles for mobile--------*/
.wide-list-group {
  line-height: 1rem;
  .list-group-item {
    padding: 0.6rem 0.25em 0.6rem 0.25em;
    cursor: pointer;
  }
  .input-number {
    max-width: 60px;
    text-align: center;
    height: 100%;
  }
  .qty-buttons {
    width: 10em;
    height: 1.6em;
    .btn {
      padding: 2px 4px;
      line-height: 18px;
      border-radius: 0;
      height: 100%;
    }
  }
  .price-box {
    width: 100%;
    max-width: 100px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .summary-price-box {
    font-size: 0.9rem;
  }

  .item-title {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .outofstock {
    font-size: 12px;
    border: 1px solid $little-white;
    height: 100%;
    width: 9em;
    padding-top: 0.2em;
    text-align: center;
    display: block;
  }
  .qtyleft {
    font-size: 12px;
    color: $danger-red;
    width: 90%;
    padding-top: 0.3rem;
    padding-left: 1.6rem;
    text-align: left;
    //width: 6.6rem;
    //line-height: 1.6rem;
    //text-align: center;
  }
}
.badge {
  font-weight: normal;
}
.row-categories {
  margin-top: 6px;
  margin-bottom: 6px;
  .col-6:first-child {
    padding-left: 15px;
    padding-right: 1px;
  }
  col-6:last-child {
    padding-left: 1px;
    padding-right: 15px;
  }
  button {
    border: 1px solid $little-white;
    max-height: 36px;
    overflow: hidden;
  }
}
.big-logo {
  max-width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal-backdrop.show {
  opacity: 0.8;
}
.bottom-nav a.link-active, .link-active, .bottom-nav a.link-active .fa {
  color: $color-main-accent;
}
.bottom-nav a.cart-active {
  color: $plain-green;
  span {
    font-weight: bold;
  }
}
.navbar a.link-active {
  color: $black !important;
}
.star-non-active {
  color: $main-text-color;
}
.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
    filter: invert(100%);
}
.carousel-item {
  height: 350px;
}
/*.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}*/
.carousel-indicators {
  bottom: -30px;
}
.grayed-out {
  color: #808080;
}
/*
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
*/
//--------------order list----------------
.orders-list {
  border-radius: 0px;
  li {
      margin-top: 1em;
  }    
  .list-group-item {
      cursor: pointer;
  }
  .list-group-item + .list-group-item {
      border-top-width: 1px;
  }
}
.ch-icon-wrap {
  font-size: 1.5em;
  margin-right:10px !important;
}
.ch-complete {
  color: $plain-green;
}
.ch-incomplete {
  color: $plain-red;
}
.qa-checked {
  font-weight: bold;
  font-size: 0.5em !important;
}
.icon-wrap {
  color: $plain-green;
  margin-right:2px;
  .fa-lock {
    color: $primary-yellow;
  }
}
.icon-stack {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
}
.icon-stack-2x {
  font-size: 1.5em;
}
.icon-stack-1x {
  font-size: 0.75em;
}
//------------end order list--------------
.date-badge {
  font-size: 1rem;
  font-weight: 500;
  height: 30px;
  min-width: 140px;
  justify-content: center;
  display: flex;
  time {
    display: flex;
  }
}